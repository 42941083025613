import { useButton } from '@diallink-corp/convergo-aria-button';
import { FocusRing } from '@diallink-corp/convergo-aria-focus';
import { useHover } from '@diallink-corp/convergo-aria-interactions';
import { mergeProps, useFallbackRef } from '@diallink-corp/convergo-aria-utils';
import { useSlotProps } from '@diallink-corp/convergo-react-layout';
import { ButtonProps, StyleProps } from '@diallink-corp/convergo-types';
import clsx from 'clsx';
import { forwardRef, RefObject } from 'react';

export interface ButtonLinkProps extends ButtonProps<'a'>, StyleProps {}

/**
 * An extension of the button component that looks like a link.
 */
export const ButtonLink = forwardRef(function ButtonLink(
  props: ButtonLinkProps,
  ref: RefObject<HTMLAnchorElement>
) {
  props = useSlotProps('link', props);
  const { isDisabled, children, autoFocus, className } = props;

  ref = useFallbackRef<HTMLAnchorElement>(ref);

  const { buttonProps, buttonState } = useButton(props, ref);
  const { hoverProps, hoverState } = useHover({ isDisabled });

  delete props.onPress;

  return (
    <FocusRing
      focusRingClassName="ring-2 ring-offset-1 ring-blue-600"
      autoFocus={autoFocus}
    >
      <a
        variant="text"
        {...mergeProps(props, buttonProps, hoverProps)}
        ref={ref}
        className={clsx(
          'cursor-pointer appearance-none rounded-lg border-none bg-transparent focus:outline-none',
          buttonState.isPressed && 'text-blue',
          !isDisabled && 'text-blue',
          isDisabled && 'text-disabled cursor-default',
          hoverState.isHovered && !isDisabled && 'text-blue',
          className
        )}
      >
        {children}
      </a>
    </FocusRing>
  );
});
